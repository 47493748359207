



import { Component, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import MessageCard from '@/components/MessageCard.vue';

@Component({
  components: {
    MessageCard,
  },
})
export default class Success extends Vue {
  type: string = this.$route.params.type;

  msg: TranslateResult = this.$t('success.goToApp');

  title: TranslateResult = this.$t('success.congratulations') ;

  mounted() {
    if (this.type === 'email') {
      this.msg = `${this.$t('success.emailSuccess')} ${this.$t('success.goToApp')}`;
    } else if (this.type === 'password') {
      this.msg = `${this.$t('success.passwordSuccess')} ${this.$t('success.goToApp')}`;
    }
  }
}
